import * as Sentry from '@sentry/vue'
import Vue from 'vue'

/**
 * @param {string} url
 * @param {number | null} code
 * @returns {boolean}
 */
function isDenied(url, code = null) {
  const denyUrls = [
    'yandex',
    'google',
  ]
  const denyCodes = [
    403,
  ]

  let denied = false

  denyUrls.forEach((denyUrl) => {
    if (url.includes(denyUrl)) {
      denied = true
    }
  })

  if (code && denied === false) {
    denyCodes.forEach((denyCode) => {
      if (code === denyCode) {
        denied = true
      }
    })
  }

  return denied
}

const isEnvSet = Boolean(process.iblsEnv.IBLS_SENTRY_DSN)

export default class SentryHelper {
  static init(router) {
    if (isEnvSet) {
      const isMasked = process.iblsEnv.IBLS_SENTRY_MASK_DATA === 'true'

      Sentry.init({
        Vue,
        release: process.iblsEnv.IBLS_SENTRY_RELEASE,
        dsn: process.iblsEnv.IBLS_SENTRY_DSN,
        environment: process.iblsEnv.IBLS_SENTRY_ENVIRONMENT,

        integrations: [
          Sentry.browserTracingIntegration({
            router,
          }),
          Sentry.replayIntegration({
            maskAllText: isMasked,
            blockAllMedia: isMasked,
          }),
        ],

        logErrors: true,
        sendDefaultPii: true,

        denyUrls: [
          'yandex',
          'google',
          /extensions\//i,
          /^chrome:\/\//i,
        ],

        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr') {
            const { xhr } = hint
            const url = String(breadcrumb.data?.url ?? xhr?.responseURL)
            const denied = isDenied(url)

            if (denied) {
              return null
            }
          }

          return breadcrumb
        },

        beforeSend(event, hint) {
          const error = hint.originalException

          if (error.request) {
            const url = error.request.responseURL
            const code = error.request.status
            const denied = isDenied(url, code)

            if (denied) {
              return null
            }
          }

          return event
        },

        normalizeDepth: 5,
        tracesSampleRate: Number(
          process.iblsEnv.IBLS_SENTRY_TRACES_SAMPLE_RATE,
        ),

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: Number(
          process.iblsEnv.IBLS_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
        ),
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: Number(
          process.iblsEnv.IBLS_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
        ),

        trackComponents: true,

        tracePropagationTargets: [
          window.domains.apiUrl,
        ],

        hooks: [
          'activate',
          'create',
          // TODO: rename to unmount in Vue 3
          'destroy',
          'mount',
          'update',
        ],
      })
    }
  }

  /**
   * @param {Object} payload
   * @param {number} payload.id
   * @param {string} payload.email
   * @param {string} payload.baseRole
   */
  static setUser({ id, email, baseRole }) {
    if (isEnvSet) {
      Sentry.setUser({
        id,
        email,
        baseRole,
      })

      Sentry.setTag('baseRole', baseRole)
    }
  }

  static resetUser() {
    if (isEnvSet) {
      Sentry.getCurrentScope().setUser(null)
    }
  }
}
